import { Button, Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import { useState } from "react";

const MyCard = ({ title, image, content }) => {
    const [isHover, setIsHover] = useState(false);

    return (
        <Card
            sx={{
                maxWidth: 325,
                backgroundColor: '#5669c1',
                color: 'white',
                borderRadius: 2
            }}
            raised={isHover}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <CardMedia
                component="img"
                alt="green iguana"
                height="180"
                image={image}
            />
            <CardContent sx={{ my: 1 }}>
                <Typography gutterBottom variant="h5" component="span">
                    {title}
                </Typography>
                <Typography variant="body2">
                    {content}
                </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
                <Button size="small" color="inherit" sx={{ fontSize: '0.75rem' }}>Learn More</Button>
            </CardActions>
        </Card >
    );
};


export default MyCard;