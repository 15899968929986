import { Avatar, Container, Typography, Grid2 as Grid, Box } from "@mui/material";
import SchoolIcon from '@mui/icons-material/School';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import HandshakeIcon from '@mui/icons-material/Handshake';

const Choose = () => {
    return (
        <Container maxWidth={'lg'} id="choose">
            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                <Typography
                    variant="h4"
                    textAlign="center"
                    gutterBottom
                    sx={{ fontWeight: 300 }}
                >
                    Why Choose
                </Typography>
                <Typography variant="h4" color='error' fontWeight='300'>
                    Us?
                </Typography>
            </Box>

            <Typography variant='subtitle1' textAlign={'center'} letterSpacing={'0.7px'}>
                When you choose us, you'll benefit from our 10 years of experience in loans, advertisements, and job consulting. We understand these areas well and know how to handle them effectively. With our knowledge in lending, marketing, and recruitment, we are ready to meet your needs and exceed your expectations.
            </Typography>

            <br />
            <br />


            <Grid container justifyContent={'center'} spacing={2}>
                <Grid size={{ xs: 12, sm: 5, md: 3 }} sx={{ display: 'grid', justifyContent: 'center' }}>
                    <Avatar
                        sx={{ height: '15rem', width: '15rem', backgroundColor: '#f5f5f5', display: 'flex', flexDirection: 'column' }}
                        className='hover-circle-affect'
                    >
                        <SchoolIcon sx={{ fontSize: '5rem' }} color='primary' />
                        <Typography
                            variant='h5'
                            color='primary'
                            fontWeight={'300'}
                            textAlign={'center'}
                        >
                            Expertise

                        </Typography>
                    </Avatar>
                </Grid>

                <Grid size={{ xs: 12, sm: 5, md: 3 }} sx={{ display: 'grid', justifyContent: 'center' }}>
                    <Avatar
                        sx={{ height: '15rem', width: '15rem', backgroundColor: '#f5f5f5', display: 'flex', flexDirection: 'column' }}
                        className='hover-circle-affect'
                    >
                        <Diversity3Icon sx={{ fontSize: '5rem' }} color='primary' />
                        <Typography
                            variant='h5'
                            color='primary'
                            fontWeight={'300'}
                            textAlign={'center'}
                        >
                            Customer <br />
                            Oriented

                        </Typography>
                    </Avatar>
                </Grid>

                <Grid size={{ xs: 12, sm: 5, md: 3 }} sx={{ display: 'grid', justifyContent: 'center' }}>
                    <Avatar
                        sx={{ height: '15rem', width: '15rem', backgroundColor: '#f5f5f5', display: 'flex', flexDirection: 'column' }}
                        className='hover-circle-affect'
                    >
                        <HandshakeIcon sx={{ fontSize: '5rem' }} color='primary' />
                        <Typography
                            variant='h5'
                            color='primary'
                            fontWeight={'300'}
                            textAlign={'center'}
                        >
                            Reliability
                        </Typography>
                    </Avatar>
                </Grid>
            </Grid>
        </Container >
    );
}

export default Choose;