import { Box, Modal, Typography } from "@mui/material";
import Logo from '../../assets/logo.png';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth: "80%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    backgroundColor: "#3d52a0",
    color: "#f5f5f5",
    border: '0.1px solid #7091e6'
};

const ContactModal = ({ open, handleClose }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <img
                    src={Logo}
                    style={{ display: 'block', maxWidth: 120 }}
                    alt='brand logo'
                />
                <br />
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Contact Us
                </Typography>

                <Typography sx={{ mt: 2 }}>
                    <PhoneIcon fontSize="small" /> Landline: <span>0542-4106540</span>
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    <PhoneIphoneIcon fontSize="small" /> Mobile No: <a href="tel:+916386018016"
                        style={{ color: "#f5f5f5" }}
                    >
                        +91 6386018016
                    </a>
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    <EmailIcon fontSize="small" /> Email: <a href="mailto:twins7opc@gmail.com"
                        style={{ color: "#f5f5f5" }}
                    >
                        twins7opc@gmail.com
                    </a>
                </Typography>
            </Box>
        </Modal>
    );
};

export default ContactModal;