import './home.css';
import Image1 from '../../assets/1.png';
import Image2 from '../../assets/2.png';
import Image3 from '../../assets/3.png';
import { Box, Typography } from "@mui/material";
import Carousel from 'react-material-ui-carousel';

const Banner = () => {
    var items = [
        Image1,
        Image2,
        Image3
    ];

    const Item = ({ image }) => {
        return (
            <Box
                sx={{
                    height: {
                        xs: '300px',
                        md: '100px',
                        lg: "450px"
                    },
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Typography variant='h2'>
                    <Typography color='error' component='span' variant='h2'>T</Typography>WINS&nbsp;7
                </Typography>

                <Typography variant='subtitle1' textAlign={'center'}>
                    Your one-stop solution for loans, career advice, and advertising excellence! 🚀
                </Typography>

            </Box>
        )
    };

    return (
        <Carousel
            indicators={null}
        >
            {
                items.map((item, i) => <Item key={i} image={item} />)
            }
        </Carousel>
    )
}

export default Banner;