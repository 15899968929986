import { Typography, Container, Grid2 as Grid, Box } from "@mui/material";
import MyCard from "./Card";
import Job from '../../assets/job.png';
import Finance from '../../assets/finance.png';
import Adver from '../../assets/adver.png';

const Services = () => {
    return (
        <Container maxWidth={'lg'} disableGutters id="services">
            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                <Typography
                    variant="h4"
                    textAlign="center"
                    gutterBottom
                    sx={{ fontWeight: 300 }}
                >
                    Our
                </Typography>

                <Typography variant="h4" color='error' sx={{ fontWeight: 300 }}>
                    Services
                </Typography>

            </Box>
            <Typography gutterBottom variant='subtitle1' textAlign={'center'} letterSpacing={'0.7px'}>
                Unlock endless possibilities with our expert job consultancy, tailored loan services, and strategic advertising solutions. At Twins7, we're dedicated to empowering your personal and professional growth.
            </Typography>
            <br />

            <Grid
                container
                spacing={2}
                justifyContent={"center"}
                alignItems='center'
            >
                <Grid size={{ xs: 9, sm: 5, lg: 4 }}>
                    <MyCard
                        title="Job Consultant"
                        content="Unlock your career potential with our expert job consulting services. We connect you with opportunities that match your skills and aspirations."
                        image={Job}
                    />
                </Grid>

                <Grid size={{ xs: 9, sm: 5, lg: 4 }}>
                    <MyCard
                        title="Finance"
                        content="Navigate your financial future with confidence. Our tailored finance solutions help you achieve your goals and secure your financial well-being."
                        image={Finance}
                    />
                </Grid>

                <Grid size={{ xs: 9, sm: 5, lg: 4 }}>
                    <MyCard
                        title="Advertisement"
                        content="Boost your brand’s visibility with our targeted advertising solutions. Reach your audience effectively and drive impactful results"
                        image={Adver}
                    />
                </Grid>
            </Grid>

        </Container>
    )
}

export default Services;