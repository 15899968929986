import { Col, Row } from "react-bootstrap";
import './home.css';
import Logo from '../../assets/logo.png';
// import Image1 from '../../assets/1.png';
// import Image2 from '../../assets/2.png';
// import Image3 from '../../assets/3.png';
// import Icon1 from '../../assets/icon1.png';
// import Icon2 from '../../assets/icon2.png';
// import Icon3 from '../../assets/icon3.png';
// import Why from '../../assets/why.png';
import { AppBar, Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography, Container, Grid2 as Grid } from "@mui/material";
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { HelpOutline, MenuOpen } from "@mui/icons-material";
import Banner from './Banner';
import Choose from './Choose';
import Services from './Services';
import ContactModal from './ContactModal';



function Home() {
    return (
        <Container
            id="home"
            maxWidth={false} disableGutters
            sx={{
                height: {
                    lg: '100vh',
                    xs: '100dvh'
                },
                width: {
                    lg: "100vw",
                    xs: "100dvw"
                }
            }}
        >
            <MyNav />
            <Box component="main" sx={{ display: 'grid', gap: '3rem', backgroundColor: '#ede8f5' }}>
                <Banner />
                <Services />
                <Choose />
                {/* <About /> */}
                <Footer />
            </Box>
        </Container>
    );
}


const MyNav = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const navItems = [
        {
            id: "home",
            name: "Home"
        },
        {
            id: "services",
            name: "Services"
        },
        {
            id: "choose",
            name: "Why Choose Us?"
        },
        {
            id: "contactus",
            name: "Contact Us"
        }
    ];
    const drawerWidth = 240;

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const navigateTo = (id) => {
        const page = window.location.protocol + '//' + window.location.host + '#' + id;
        window.location.href = page;
    };

    const drawer = (
        <Box onClick={handleDrawerToggle}
            sx={{
                backgroundColor: '#3d52a0',
                flexGrow: 1,
                color: "#EDE8F5",
                p: 1
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <img
                    src={Logo}
                    style={{ display: 'block', maxWidth: "50%" }}
                    alt='brand logo'
                />
                <IconButton>
                    <MenuOpen sx={{ color: "#EDE8F5" }} />
                </IconButton>
            </Box>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem
                        key={item.id}
                        disablePadding
                        onClick={() => navigateTo(item.id)}
                    >
                        <ListItemButton>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window.document.body : undefined;


    return (
        <>
            <AppBar component="nav" sx={{ backgroundColor: '#3d52a0' }} position="fixed">
                <Toolbar variant="dense">
                    <IconButton
                        color="inherit"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { lg: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {/* Name */}
                    <img
                        src={Logo}
                        style={{ display: 'block', maxWidth: 120 }}
                        alt='brand logo'
                    />

                    <Box sx={{ display: { xs: 'none', lg: 'flex' }, flexGrow: 1, justifyContent: 'center' }}>
                        {navItems.map((item) => (
                            <Button key={item.name} sx={{ color: '#fff' }} onClick={() => navigateTo(item.id)}>
                                {item.name}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: { xs: 1, lg: 0 }, display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton
                            color="inherit"
                            onClick={() => setIsOpen(true)}
                        >
                            <HelpOutline fontSize="medium" />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
            <ContactModal open={isOpen} handleClose={() => setIsOpen(!isOpen)} />
        </>
    );
}


const Footer = () => {
    return (
        <Container id="contactus" maxWidth={false} disableGutters sx={{ backgroundColor: '#3d52a0', color: 'white', p: 2, py: 10 }}>
            <Container maxWidth='lg'>
                <Grid container rowGap={2}>
                    <Grid size={{ xs: 12, md: 4 }}>
                        <Typography variant='h4'>
                            Address
                        </Typography>
                        <Typography component={'p'}>
                            Twins 7 MANAGEMENT OPC PVT LTD. <br />
                            B - 38 / 1 - E <br />
                            Birdopur Mahmoorganj, Varanasi, 221010.<br />
                            Near Ashirwad Hospital Usha Gupta<br />
                        </Typography>
                    </Grid>
                    <Grid size={{ xs: 12, md: 4 }}>
                        <Typography variant='h4'>
                            Follow Us
                            <Typography component={'p'}>
                                Facebook <br />
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid size={{ xs: 12, md: 4 }}>
                        <Typography variant='h4'>
                            Contact Us
                        </Typography>

                        <Typography component={'p'}>
                            <span>0542-4106540</span> <br />
                            <a href="tel:+916386018016"
                                style={{ color: "#f5f5f5", textDecoration: 'none' }}
                            >
                                +91 6386018016
                            </a> <br />
                            <a href="mailto:twins7opc@gmail.com"
                                style={{ color: "#f5f5f5", textDecoration: 'none' }}
                            >
                                twins7opc@gmail.com
                            </a>
                            <br />
                        </Typography>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <Typography component='p' textAlign='center'>
                            Designed and developed by Mahadev!
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

            {/* <Row>
                <Col sm={12} md={4}>
                    <h4></h4>
                    <p>
                        
                    </p>
                </Col>
                <Col sm={12} md={4}>
                    <h4></h4>
                    <p>
                        
                    </p>
                </Col>
                <Col sm={12} md={4}>
                    <h4></h4>
                    <p>
                        <span>0542-4106540</span> <br />
                        <a href="tel:+916386018016">+91 6386018016</a> <br />
                        <a href="mailto:twins7opc@gmail.com">twins7opc@gmail.com</a>
                        <br />
                    </p>
                </Col>
                <p>Designed and developed by Shiv!</p>
            </Row> */}
        </Container>
    );
}


const About = () => {
    return (
        <Container>
            <div className="center">
                <h1>About Us</h1>
                <br /><br />
                <br />
            </div>
            <Row>
                <Col sm={12} md={6} className="center">
                    <img src={Logo} className="aboutImage" alt="name of the" />
                </Col>
                <Col sm={12} md={6} id="aboutText">
                    <span >
                        Twin’s 7 is a company that offers various services to help you achieve your personal and professional goals. Whether you need a loan, a job consultation, or an advertisement service, we have the right solution for you. <br /><br />

                        At Twin’s 7, we believe in providing quality services at affordable prices. We have a team of experts who can guide you through the process of applying for a loan, finding a suitable job, or creating an effective advertisement campaign. We also have a network of partners who can offer you the best deals and opportunities in the market.<br /><br />

                        Our mission is to help you succeed in life by providing you with the services you need. We are committed to customer satisfaction and excellence. We are always ready to listen to your feedback and suggestions. We are Twin’s 7, your trusted partner in achieving your dreams.<br />
                    </span>
                    <br /><br />
                </Col>

            </Row>
        </Container>
    )
}




export default Home;
